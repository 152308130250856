.micrio-container .logo {
    display: none;
}

/* This hides the original title that is not used but has default margin */
.micrio-container .marker-container {
    width: 320px;
    pointer-events: none;
}

.micrio-container .marker-popup {
    background: white;
    padding: 24px;
    position: absolute;
    top: 0;
    left: 0;
    width: 380px;
    pointer-events: all;
}

.micrio-container.extra-small .marker-popup {
    width: auto;
    margin: 64px 17px 96px;
    right: 0;
}

.micrio-container .marker-container h3 {
    display: none;
}

.micrio-container .close {
    background: transparent;
    position: absolute;
    top: 24px;
    right: 24px;
    pointer-events: all;
}

.micrio-container .close:before {
    font-size: 20px;
}

.popover-container {
    background: rgba(0,0,0,0.75);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.popover-container .popover {
    width: 80%;
    background: white;
    padding: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.popover-container .popover h4 {
    font-size: 26px;
    text-transform: uppercase;
    margin: 0;
}

.popover-container .popover button {
    border: none;
    color: white;
    font-weight: bold;
    pointer-events: all;
    height: 50px;
    font-size: 18px;
    line-height: 18px;
    padding: 0 24px;
    text-transform: uppercase;
    margin: 16px 8px 0 0;
    background: black;
}

.article {
  font-size: 18px;
  line-height: 28px;
}

.article.extremely-large {
  font-size: 20px;
  line-height: 30px;
}

.article.extra-small {
  font-size: 15px;
  line-height: 22px;
}

.article h1 {
  grid-column: 1;
  margin: 0;
  font-size: 52px;
  line-height: 1.2;
  text-transform: uppercase;
  height: 0;
  overflow: visible;
}

@media only screen and (max-width: 1024px) {
  .article h1 {
    height: auto;
    margin-bottom: 20px;
  }
}

.article.extra-small h1 {
  font-size: 30px;
}

.article.medium h1 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.article.medium p ~ h1:first-of-type {
  margin-top: 0px;
}
.article.medium p {
  margin-top: 0;
}

.article h2 {
  text-transform: uppercase;
}

.article > * {
  grid-column: 2;
  margin: 0 0 40px;
}

.article h2,
.article h3,
.article h4,
.article h5,
.article h6 {
  margin-bottom: 20px;
}

.article p {
  display: block;
}
.article p:first-child {
  grid-column: 1 / 3;
}
.article.extra-small p:first-child {
  margin-bottom: 10px;
}

.article p a,
.article li a {
  text-decoration: underline;
  display: inline-block;
}

.article p a:hover,
.article li a:hover {
  text-decoration: none;
}

.article ul {
  list-style-type: none;
  padding: 0;
  position: relative;
}

.article li {
  padding-left: 24px;
}

.article.extra-small ul li {
  padding-left: 4px;
}
.article.extra-small ol {
  padding-left: 20px;
}
.article.extra-small ol li {
  padding-left: 4px;
}

.article ul li:before {
  content: '-';
  position: absolute;
  left: 0;
}

/* Indexpagina styling */
.article ol[start='0'] {
  list-style: none;
  counter-reset: index-counter -1;
  padding-left: 0;
}
.article ol[start='0'] li {
  position: relative;
  padding-left: 42px;
}
.article.extra-small ol[start='0'] li {
  padding-left: 36px;
}
.article ol[start='0'] li + li {
  margin-top: 12px;
}
.article.extra-small ol[start='0'] li + li {
  margin-top: 10px;
}
.article ol[start='0'] li:before {
  content: counter(index-counter) '.';
  counter-increment: index-counter;
  position: absolute;
  left: 0;
  top: 0;
  width: 32px;
  text-align: right;
  font-weight: bold;
  font-size: 18px;
}
.article.extra-small ol[start='0'] li:before {
  width: 28px;
  font-size: 16px;
}
.article ol[start='0'] li a {
  text-decoration: none;
}
.article ol[start='0'] li a:hover {
  text-decoration: underline;
}

.article img {
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 360px;
  display: block;
  margin: 0 auto;
}

.article p:first-child img {
  width: 100%;
  height: 360px;
  object-fit: cover;
  font-family: 'object-fit: cover';
}

.article.extra-small p:first-child img {
  height: auto;
  width: 100%;
}

.article img + strong,
.article img + em {
  margin-top: 20px;
  display: block;
  font-size: 18px;
}

.article.extra-small img + strong,
.article.extra-small img + em {
  margin-top: 10px;
  font-size: 15px;
}

.article iframe {
  width: 100%;
}

.article blockquote {
  grid-column: 1;
  margin: 0;
  font-size: 26px;
  line-height: 36px;
  font-weight: bold;
  height: 0;
  overflow: visible;
  padding-top: 60px;
}

.article blockquote p:first-of-type {
  margin-top: 0;
}

.article blockquote p:last-of-type {
  font-weight: normal;
}

@media only screen and (max-width: 1024px) {
  .article blockquote {
    height: auto;
    margin-left: 0;
    padding-top: 0;
    font-size: 18px;
    line-height: 28px;
  }
  .article blockquote p {
    margin-top: 0px;
  }
}

micr-io {
    --micrio-icon-size: 20px;
    --micrio-border-margin: 70px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
}

micr-io canvas.micrio {
    height: calc(100% - 90px) !important;
    will-change: height;
}

micr-io[zoom="isZoomedIn"] canvas.micrio {
    height: 100% !important;
}

micr-io ul.gallery,
micr-io button.micrio-action {
    will-change: opacity;
    transition: opacity 0.2s ease-out;
}

micr-io[zoom="isZoomedIn"] ul.gallery,
micr-io[zoom="isZoomedIn"] button.micrio-action,
micr-io[idle="isIdle"] ul.gallery,
micr-io[idle="isIdle"] button.micrio-action {
    opacity: 0;
}

micr-io ul.gallery {
    background: none;
    left: 0;
    right: 0;
    bottom: 6px;
    position: fixed;
    margin: 0 auto;
    padding: 0;
    -webkit-transform: initial;
            transform: initial;
    align-items: flex-end;
    width: 100%;
    max-width: calc(100vw - var(--micrio-border-margin) - var(--micrio-border-margin));
    justify-content: space-between;
}

@media only screen and (max-width: 1400px) {
    micr-io ul.gallery {
        width: 100%;
    }
}

@media only screen and (max-width: 1024px) {
    micr-io {
        --micrio-border-margin: 27px;
    }
}

@media only screen and (max-width: 700px) {
    micr-io {
        --micrio-border-margin: 17px;
    }
}

micr-io li.gallery {
    color: transparent;
    opacity: 1;
}

micr-io li.gallery {
    width: 6px;
    margin-right: -6px;
    flex-basis: 6px;
    flex-grow: 0;
    flex-shrink: 0;
    height: 52px;
    display: block;
    transition: none;
    position: relative;
}

micr-io li.gallery.gallery:first-of-type,
micr-io li.gallery.gallery:last-of-type {
    content: "";
    width: 6px;
    height: 24px;
    background-color: black;
}

micr-io li.gallery.active {
    height: 52px !important;
    width: 6px;
    background: black;
}

micr-io button.gallery {
    border-radius: 0;
}

micr-io button.gallery,
micr-io button.gallery:hover {
    background: transparent;
}

micr-io button.micrio-action {
    border-radius: 0;
    box-shadow: initial;
    background-color: black;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 1px;
    transition: opacity 0.15s linear;
}

micr-io button.micrio-action[disabled] {
    transition: none;
    opacity: 0;
}

micr-io button.micrio-action:hover {
    background: black;
    color: white;
}

micr-io button.micrio-action span {
    will-change: transform;
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: -webkit-transform 0.15s linear;
    transition: transform 0.15s linear;
    transition: transform 0.15s linear, -webkit-transform 0.15s linear;
    color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 0;
}

micr-io button.arrow-right span,
micr-io button.arrow-left span {
    background-image: url(/static/media/arrow-right.e0ed8411.svg);
}

micr-io button.arrow-left span {
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
}

micr-io button.arrow-left:hover span {
    -webkit-transform: scaleX(-1) scale(0.9);
            transform: scaleX(-1) scale(0.9);
}

micr-io button.arrow-right:hover span {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
}

