.micrio-container .logo {
    display: none;
}

/* This hides the original title that is not used but has default margin */
.micrio-container .marker-container {
    width: 320px;
    pointer-events: none;
}

.micrio-container .marker-popup {
    background: white;
    padding: 24px;
    position: absolute;
    top: 0;
    left: 0;
    width: 380px;
    pointer-events: all;
}

.micrio-container.extra-small .marker-popup {
    width: auto;
    margin: 64px 17px 96px;
    right: 0;
}

.micrio-container .marker-container h3 {
    display: none;
}

.micrio-container .close {
    background: transparent;
    position: absolute;
    top: 24px;
    right: 24px;
    pointer-events: all;
}

.micrio-container .close:before {
    font-size: 20px;
}

.popover-container {
    background: rgba(0,0,0,0.75);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.popover-container .popover {
    width: 80%;
    background: white;
    padding: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.popover-container .popover h4 {
    font-size: 26px;
    text-transform: uppercase;
    margin: 0;
}

.popover-container .popover button {
    border: none;
    color: white;
    font-weight: bold;
    pointer-events: all;
    height: 50px;
    font-size: 18px;
    line-height: 18px;
    padding: 0 24px;
    text-transform: uppercase;
    margin: 16px 8px 0 0;
    background: black;
}
