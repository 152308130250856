micr-io {
    --micrio-icon-size: 20px;
    --micrio-border-margin: 70px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
}

micr-io canvas.micrio {
    height: calc(100% - 90px) !important;
    will-change: height;
}

micr-io[zoom="isZoomedIn"] canvas.micrio {
    height: 100% !important;
}

micr-io ul.gallery,
micr-io button.micrio-action {
    will-change: opacity;
    transition: opacity 0.2s ease-out;
}

micr-io[zoom="isZoomedIn"] ul.gallery,
micr-io[zoom="isZoomedIn"] button.micrio-action,
micr-io[idle="isIdle"] ul.gallery,
micr-io[idle="isIdle"] button.micrio-action {
    opacity: 0;
}

micr-io ul.gallery {
    background: none;
    left: 0;
    right: 0;
    bottom: 6px;
    position: fixed;
    margin: 0 auto;
    padding: 0;
    transform: initial;
    align-items: flex-end;
    width: 100%;
    max-width: calc(100vw - var(--micrio-border-margin) - var(--micrio-border-margin));
    justify-content: space-between;
}

@media only screen and (max-width: 1400px) {
    micr-io ul.gallery {
        width: 100%;
    }
}

@media only screen and (max-width: 1024px) {
    micr-io {
        --micrio-border-margin: 27px;
    }
}

@media only screen and (max-width: 700px) {
    micr-io {
        --micrio-border-margin: 17px;
    }
}

micr-io li.gallery {
    color: transparent;
    opacity: 1;
}

micr-io li.gallery {
    width: 6px;
    margin-right: -6px;
    flex-basis: 6px;
    flex-grow: 0;
    flex-shrink: 0;
    height: 52px;
    display: block;
    transition: none;
    position: relative;
}

micr-io li.gallery.gallery:first-of-type,
micr-io li.gallery.gallery:last-of-type {
    content: "";
    width: 6px;
    height: 24px;
    background-color: black;
}

micr-io li.gallery.active {
    height: 52px !important;
    width: 6px;
    background: black;
}

micr-io button.gallery {
    border-radius: 0;
}

micr-io button.gallery,
micr-io button.gallery:hover {
    background: transparent;
}

micr-io button.micrio-action {
    border-radius: 0;
    box-shadow: initial;
    background-color: black;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 1px;
    transition: opacity 0.15s linear;
}

micr-io button.micrio-action[disabled] {
    transition: none;
    opacity: 0;
}

micr-io button.micrio-action:hover {
    background: black;
    color: white;
}

micr-io button.micrio-action span {
    will-change: transform;
    transform: scale(1);
    transition: transform 0.15s linear;
    color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 0;
}

micr-io button.arrow-right span,
micr-io button.arrow-left span {
    background-image: url("./arrow-right.svg");
}

micr-io button.arrow-left span {
    transform: scaleX(-1);
}

micr-io button.arrow-left:hover span {
    transform: scaleX(-1) scale(0.9);
}

micr-io button.arrow-right:hover span {
    transform: scale(0.9);
}
