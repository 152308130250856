.article {
  font-size: 18px;
  line-height: 28px;
}

.article.extremely-large {
  font-size: 20px;
  line-height: 30px;
}

.article.extra-small {
  font-size: 15px;
  line-height: 22px;
}

.article h1 {
  grid-column: 1;
  margin: 0;
  font-size: 52px;
  line-height: 1.2;
  text-transform: uppercase;
  height: 0;
  overflow: visible;
}

@media only screen and (max-width: 1024px) {
  .article h1 {
    height: auto;
    margin-bottom: 20px;
  }
}

.article.extra-small h1 {
  font-size: 30px;
}

.article.medium h1 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.article.medium p ~ h1:first-of-type {
  margin-top: 0px;
}
.article.medium p {
  margin-top: 0;
}

.article h2 {
  text-transform: uppercase;
}

.article > * {
  grid-column: 2;
  margin: 0 0 40px;
}

.article h2,
.article h3,
.article h4,
.article h5,
.article h6 {
  margin-bottom: 20px;
}

.article p {
  display: block;
}
.article p:first-child {
  grid-column: 1 / 3;
}
.article.extra-small p:first-child {
  margin-bottom: 10px;
}

.article p a,
.article li a {
  text-decoration: underline;
  display: inline-block;
}

.article p a:hover,
.article li a:hover {
  text-decoration: none;
}

.article ul {
  list-style-type: none;
  padding: 0;
  position: relative;
}

.article li {
  padding-left: 24px;
}

.article.extra-small ul li {
  padding-left: 4px;
}
.article.extra-small ol {
  padding-left: 20px;
}
.article.extra-small ol li {
  padding-left: 4px;
}

.article ul li:before {
  content: '-';
  position: absolute;
  left: 0;
}

/* Indexpagina styling */
.article ol[start='0'] {
  list-style: none;
  counter-reset: index-counter -1;
  padding-left: 0;
}
.article ol[start='0'] li {
  position: relative;
  padding-left: 42px;
}
.article.extra-small ol[start='0'] li {
  padding-left: 36px;
}
.article ol[start='0'] li + li {
  margin-top: 12px;
}
.article.extra-small ol[start='0'] li + li {
  margin-top: 10px;
}
.article ol[start='0'] li:before {
  content: counter(index-counter) '.';
  counter-increment: index-counter;
  position: absolute;
  left: 0;
  top: 0;
  width: 32px;
  text-align: right;
  font-weight: bold;
  font-size: 18px;
}
.article.extra-small ol[start='0'] li:before {
  width: 28px;
  font-size: 16px;
}
.article ol[start='0'] li a {
  text-decoration: none;
}
.article ol[start='0'] li a:hover {
  text-decoration: underline;
}

.article img {
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 360px;
  display: block;
  margin: 0 auto;
}

.article p:first-child img {
  width: 100%;
  height: 360px;
  object-fit: cover;
  font-family: 'object-fit: cover';
}

.article.extra-small p:first-child img {
  height: auto;
  width: 100%;
}

.article img + strong,
.article img + em {
  margin-top: 20px;
  display: block;
  font-size: 18px;
}

.article.extra-small img + strong,
.article.extra-small img + em {
  margin-top: 10px;
  font-size: 15px;
}

.article iframe {
  width: 100%;
}

.article blockquote {
  grid-column: 1;
  margin: 0;
  font-size: 26px;
  line-height: 36px;
  font-weight: bold;
  height: 0;
  overflow: visible;
  padding-top: 60px;
}

.article blockquote p:first-of-type {
  margin-top: 0;
}

.article blockquote p:last-of-type {
  font-weight: normal;
}

@media only screen and (max-width: 1024px) {
  .article blockquote {
    height: auto;
    margin-left: 0;
    padding-top: 0;
    font-size: 18px;
    line-height: 28px;
  }
  .article blockquote p {
    margin-top: 0px;
  }
}
